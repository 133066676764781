import { useEffect, useState } from 'react'
import { faJava, faPython, faHtml5, faJsSquare, faGithub } from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className='container about-page'>
        <div className='text-zone'>
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            I'm a sophomore at Arizona State University majoring Computer Science with Concentration in Software
            Engineering.
          </p>
          {/*<p align='LEFT'>*/}
          {/*  */}
          {/*</p>*/}
          <p>
            I'm currently looking for Software Engineering internships for Summer 2023.
          </p>
        </div>

        <div className='stage-cube-cont'>
          <div className='cubespinner'>
            <div className='face1'>
              <FontAwesomeIcon icon={faJava} color='#DD0031' />
            </div>
            <div className='face2'>
              <FontAwesomeIcon icon={faPython} color='#F06529' />
            </div>
            <div className='face3'>
              <FontAwesomeIcon icon={faJava} color='#28A4D9' />
            </div>
            <div className='face4'>
              <FontAwesomeIcon icon={faHtml5} color='#5ED4F4' />
            </div>
            <div className='face5'>
              <FontAwesomeIcon icon={faGithub} color='#EFD81D' />
            </div>
            <div className='face6'>
              <FontAwesomeIcon icon={faJsSquare} color='#EC4D28' />
            </div>
          </div>
        </div>
      </div>
      <Loader type='pacman' />
    </>
  )
}

export default About
